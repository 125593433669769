import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import useBaseUtils from "~/functions/useBaseUtils";
export default defineComponent({
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      dev = _useBaseUtils.dev,
      refCode = _useBaseUtils.refCode,
      I18nFn = _useBaseUtils.I18nFn;
    return {
      dev: dev,
      refCode: refCode,
      I18nFn: I18nFn
    };
  },
  data: function data() {
    return {
      cid: null,
      tid: null,
      companyname: "",
      error: null
    };
  },
  computed: {
    ric: function ric() {
      return this.$route.query.id;
    }
  },
  created: function created() {
    var _this = this; // things here
    if (this.ric) {
      // if (
      //   this.$store.state.trkd.ticker &&
      //   this.$store.state.trkd.ticker.RIC &&
      //   this.$store.state.trkd.ticker.RIC === this.ric
      // ) {
      //   // this ticker is already selected ...
      //
      // } else {
      // }
      var ric = this.ric; // TODO: test this ric to see if it is in the form RDSa
      // if it is, split the ric between upper and lowercase stuff
      this.$store.dispatch("trkd/cidTidFromRic", {
        ric: ric
      }).then(function (_ref) {
        var data = _ref.data;
        if (_this.dev) {
          console.log("trkd response: ", data);
        }
        if (data && data.rowCount > 0) {
          var cid = data.cid;
          var tid = data.tid;
          if (cid && tid) {
            var toPath = "/stock/about";
            _this.$router.replace({
              path: toPath,
              query: {
                cid: cid,
                tid: tid,
                ref: _this.refCode
              }
            });
          } else {
            console.error("No res.data.data?: ", data); // set all of the properties and create the error message
          }
        } else {
          // TODO: some other code to fetch something and show it
          // just from Refinitiv if we have access to that
          throw new Error("Sorry, we don't seem to have data for ".concat(ric, ". Please contact support@tikr.com if you think this is an error"));
        }
      })["catch"](function (err) {
        _this.error = err;
        if (_this.dev) {
          console.log("trkd error", err);
        }
      })["finally"](function () {
        _this.loading = false;
      });
    }
  }
});